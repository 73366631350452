<script>
  import { user } from "../../stores/User";
  import { BASE_URL } from "../../stores/BASE_URL";
  import uuid from "uuid-v4";
  import Accordion from "../Accordion.svelte";
  import GeneralCorreos from "../empresa/General_correos.svelte";
  import GeneralDatos from "../empresa/General_datos.svelte";
  import GeneralDirecciones from "../empresa/General_direcciones.svelte";
  import GeneralRepresentantes from "../empresa/General_representantes.svelte";
  import GeneralTelefonos from "../empresa/General_telefonos.svelte";
  import SinInformacion from "../../components/Sin_informacion.svelte";
  import Spinner from "../../components/Spinner.svelte";
  import Modal from "sv-bootstrap-modal";
  import { navigate } from "svelte-routing";
  import { Datatable } from "svelte-simple-datatables";

  export let documento;

  let data = {
    persona: undefined,
    loading: false,
  };

  let session_key = $user.session_key;

  let isOpen = false;
  let ruc_f = "";
  let empresa_f = "";

  let modal = {
    loading: false,
  };

  const settings = {
    sortable: true,
    pagination: true,
    rowsPerPage: 12,
    columnFilter: false,
    scrollY: false,
    blocks: {
      searchInput: false,
      paginationButtons: true,
      paginationRowCount: false,
    },
    labels: {
      previous: "Anterior",
      next: "Siguiente",
    },
  };

  let rows;

  const cargarDatos = async () => {
    const ops = "laboral_datos";
    data.loading = true;
    const response = await fetch(`${BASE_URL}persona.php?documento=${documento}&session_key=${session_key}&ops=${ops}`);
    const resp = await response.json();

    if (resp.status == "error") {
      navigate("/", { replace: true });
    }

    data = {
      persona: resp.data,
      loading: false,
    };
  };

  const getEmpresa = (ruc, empresa) => {
    //empresa = emp
    modal.loading = true;
    isOpen = true;
    ruc_f = ruc;
    empresa_f = empresa;
    modal.loading = false;
  };

  let load = {
    correos: false,
    representantes: false,
    direcciones: false,
    correos: false,
    telefonos: false,
    empleados: false,
  };

  const click = (ops) => {
    switch (ops) {
      case "correos":
        load.correos = true;
        break;
      case "representantes":
        load.representantes = true;
        break;
      case "direcciones":
        load.direcciones = true;
        break;
      case "correos":
        load.correos = true;
        break;
      case "telefonos":
        load.telefonos = true;
        break;
      case "empleados":
        load.empleados = true;
        break;
    }
  };

  cargarDatos();

  let encabezados = ["RUC", "Empresa", "Fecha reporte", "Condición", "P1"];
</script>

{#if data.loading === true}
  <Spinner />
{:else if data.persona.length === 0}
  <SinInformacion />
{:else if data.persona !== undefined}
  <div>
    <Datatable {settings} data={data.persona} bind:dataRows={rows}>
      <table class="table table-striped table-sm">
        <thead>
          <tr>
            {#each encabezados as encabezado}
              <th class="text-secondary fw-normal">{encabezado}</th>
            {/each}
          </tr>
        </thead>
        <tbody>
          {#if rows}
            {#each $rows as planilla}
              <tr>
                <td>
                  <span class="text-primary text-action" on:click={getEmpresa(planilla.ruc, planilla.razonsocial)}>
                    {planilla.ruc}
                  </span>
                </td>
                <td>{planilla.razonsocial}</td>
                <td>{planilla.devengue}</td>
                <td>{planilla.condicion}</td>
                <td>{planilla.ingreso}</td>
              </tr>
            {/each}
          {/if}
        </tbody>
      </table>
    </Datatable>
  </div>

  <Modal bind:open={isOpen} dialogClasses="modal-fullscreen modal-dialog-scrollable">
    <div class="modal-header">
      <h5 class="modal-title text-secondary">{ruc_f} - {empresa_f}</h5>
      <button type="button" class="btn-close" on:click={() => (isOpen = false)} />
    </div>
    <div class="modal-body fs-6">
      {#if modal.loading === true}
        <Spinner />
      {:else}
        <Accordion title="Datos Generales" idx={uuid()} show={true}>
          <GeneralDatos ruc={ruc_f} {documento} />
        </Accordion>
        <Accordion title="Representantes" idx={uuid()} on:click={() => click("representantes")}>
          {#if load.representantes}
            <GeneralRepresentantes ruc={ruc_f} {documento} />
          {/if}
        </Accordion>
        <Accordion title="Direcciones" idx={uuid()} on:click={() => click("direcciones")}>
          {#if load.direcciones}
            <GeneralDirecciones ruc={ruc_f} {documento} />
          {/if}
        </Accordion>
        <Accordion title="Correos" idx={uuid()} on:click={() => click("correos")}>
          {#if load.correos}
            <GeneralCorreos ruc={ruc_f} {documento} />
          {/if}
        </Accordion>
        <Accordion title="Teléfonos" idx={uuid()} on:click={() => click("telefonos")}>
          {#if load.telefonos}
            <GeneralTelefonos ruc={ruc_f} {documento} />
          {/if}
        </Accordion>
      {/if}
    </div>
  </Modal>
{/if}

<style>
  .text-action {
    cursor: pointer;
  }
  .text-action:hover {
    --bs-text-opacity: 0.7;
  }
</style>
